import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'react-simple-snackbar'
import FullWidth from '../../components/basic/FullWidth';
import empty from '../../assets/images/cart empty.svg';
import ProductContainer from '../../components/custom/ProductCard';
import InvoiceCard from '../../components/custom/InvoiceCard';
import { getDeliveryMods } from '../../actions/store';
import { minimumCart, refreshCart } from '../../actions/cart';


class MyCart extends Component {
    state = {  }

    componentDidMount(){
        setTimeout(()=>{
            const {
                cart,
                store,
                onRefreshCart,
                onCheckMinimumCart,
                onGetDeliveryMods,
                location
            } = this.props
            if(cart && cart.length > 0){
                var data = {products: cart}
                onRefreshCart(data)
                if(store && location){
                    onGetDeliveryMods(store.id, location.latitude, location.longitude)
                }
            }
        }, 100)
    }

    componentWillUnmount(){
        const { closeSnackbar } = this.props
        if(closeSnackbar) closeSnackbar()
    }

    onCheckout = ()=>{
        const { 
            loggedIn, 
            user, 
            minimum_amount, 
            total, 
            openSnackbar,
            store,
            available 
        } = this.props

        if(loggedIn && user && store){
            if(parseFloat(store.min_amount) > parseFloat(total)){
                openSnackbar( 'Cart amount should be minimum '+ store.min_amount +" "+store.currency.symbol)
            }
            if(!available){
                openSnackbar( 'Delivery not available on selected location')
            }else{
                this.props.history.push('/checkout')
            }
        }else{
            this.props.history.push('/login')
        }
    }

    render() { 
        const { cart, history } = this.props

        if(!cart || cart.length < 1){
            return(
                <FullWidth SectionClass="cart-wrapper pb-100">
                    <div className="empty-product" style={{marginTop:50}}>
                        <img src={empty} alt="fmart" className="img-fluid"/>
                        <h3>Your Cart is empty</h3>
                    </div>
                </FullWidth>
            )
        }

        return ( 
            <FullWidth SectionClass="cart-wrapper pb-100">
                            <div className="sect-tile"><h3>Cart</h3></div>
                <div className="row">
                    <div className="col-md-8">
                    <div className="my-cart-table">
                            <div className="cart-item">
                                {cart.map((item, index)=>(
                                    <ProductContainer 
                                        key={index} 
                                        {...item} 
                                        horizontal 
                                        history={history}
                                        cart/>    
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <InvoiceCard
                       buttonText = 'CHECKOUT'
                       onPress = {this.onCheckout}
                       />
                    </div>
                </div>
            </FullWidth>  
        );
    }
}

const mapStateToProps = state => ({
    cart: state.cartReducer.cart,
    total : state.cartReducer.total,
    store : state.storeReducer.store,
    loggedIn : state.userReducer.loggedIn,
    user: state.userReducer.user,
    available: state.cartReducer.available,
    location: state.userReducer.location
});
const mapDispatchToProps = dispatch => ({
    onRefreshCart:(data)=>{
        dispatch(refreshCart(data))
    },
    onCheckMinimumCart:(store_id)=>{
        dispatch(minimumCart(store_id))
    },
    onGetDeliveryMods:(store_id, latitude, longitude)=>{
        dispatch(getDeliveryMods(store_id, latitude, longitude))
    }
});
const MyCartContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (MyCart);
export default withSnackbar(withRouter(MyCartContainer));

const options = {
    position: 'top-center',
}
  
