import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import ProductContainer from '../../components/custom/ProductCard';

class ProductsSlider extends Component {

  render() { 
    const {list, history } = this.props
    if(!list || list.length < 1) return null
    return ( 
      <div className="deals">
          <Swiper slidesPerView={1} spaceBetween={10}  breakpoints={{
          "@0.00": {
          "slidesPerView": 1,
          "spaceBetween": 10
          },
          "@0.75": {
          "slidesPerView": 2,
          "spaceBetween": 20
          },
          "@1.00": {
          "slidesPerView": 3,
          "spaceBetween": 20
          },
          "@1.50": {
          "slidesPerView": 4,
          "spaceBetween": 20
          }
          }} className="mySwiper">
          {list.map((product, index)=>(
           <SwiperSlide>
                <ProductContainer {...product} key={index} history={history}/>
           </SwiperSlide>
          ))}
          </Swiper>
      </div>
    );
  }
}
 
export default ProductsSlider;