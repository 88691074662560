import React, { Component } from 'react';
import { connect } from 'react-redux';

import ColWidth from '../basic/ColWidth';
import FullWidth from '../basic/FullWidth';
import {Link} from 'react-router-dom';
import { List } from 'semantic-ui-react';
import AppStore from '../../assets/images/APPLESTORE.png';
import PlayStore from '../../assets/images/GOOGLEPLAY.png';

class Footer extends Component {
    state = {  }
    render() { 
        const { store } = this.props
        return ( 
            <React.Fragment>
                <ColWidth SectionClass="footer-wrapper">
                    
                    <div className="col-md-3">
                        <h4 className="f-head">CONTACT</h4>   
                        {store?<div>
                            <p style={{color:'white'}}>{store.title}</p>
                            <p style={{color:'white'}}>{store.street}</p>
                            <p style={{color:'white'}}>{store.contact}</p>
                            <p style={{color:'white'}}>{store.email}</p>
                        </div>:null}
                    </div>
                    <div className="col-md-3">
                        <h4 className="f-head">COMPANY</h4>   
                        <List className="f-links">
                            <List.Item><Link to="/faq">FAQ</Link></List.Item>
                            <List.Item><Link to='/about'>About Us</Link></List.Item>
                        </List>
                    </div>
                    <div className="col-md-3">
                        <h4 className="f-head">LEGAL</h4>   
                        <List className="f-links">
                            <List.Item><Link to="/terms-condition">Terms &amp; Conditions</Link></List.Item>
                            <List.Item><Link to="/privacy-policy">Privacy Policy</Link></List.Item>
                            <List.Item><Link to="/support">Support</Link></List.Item>
                        </List>
                    </div>
                    <div className="col-md-3">
                    <h4 className="f-head">DOWNLOAD</h4>
                    <div className="downloads">
                        <a target="_blank" href="/" >
                            <img src={PlayStore} alt="Play Store" className="dwd"/>
                        </a>
                        <a target="_blank" href="/">
                            <img src={AppStore} alt="App store" className="dwd"/>
                        </a>
                    </div>
                    </div>
            </ColWidth>
            <FullWidth SectionClass="copy-wrapper">
                <p>Copyright &copy; Fmart Grocer </p>
            </FullWidth>
            </React.Fragment>
         );
    }
}

const mapStateToProps = state => ({
    store: state.storeReducer.store,
});
const mapDispatchToProps = dispatch => ({
   
});
export default connect(mapStateToProps,
                        mapDispatchToProps)
                        (Footer);