import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux';


function PaymentGateway({
    order
}){

    const refA = useRef()
    useEffect(()=>{
        if(refA && refA.current){
            refA.current.submit()
        }
    })

    return(
        <form action={order.transaction.PaymentPortal} method="post" ref={refA}>
            <input type='Hidden' name='TransactionID' value={order.transaction.TransactionID}/>
        </form>
    )
}

const mapStateToProps = state => ({
    loading: state.cartReducer.loading,
    success: state.cartReducer.success,
    order: state.cartReducer.order,
    user: state.userReducer.user,
});
const mapDispatchToProps = dispatch => ({

});
export default connect(mapStateToProps,
                        mapDispatchToProps)
                        (PaymentGateway);
