import React from 'react'
import { Bounce, Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';


export default function ActivityIndicator({
    style,
    loading,
    fullScreen,
    type
}){

    const getLoder = ()=> {
        let classes = "bt-loader ";
        classes += loading ? 'bt-show' : 'bt-hide';
        return classes;
    }

    if(fullScreen){
        if(!loading) return null
        return(
            <div className={getLoder()}></div>
        )
    }

    const activityStyle = {
        display: 'flex',
        justifyContent: 'center',
        padding:30
    }

    if(type === 'circle'){
        return <div style={activityStyle}>
            <Spinner size={20} color="#f3c300"/>
        </div>
    }

    return <div style={activityStyle}>
            <Bounce size={40} color="#f3c300"/>
        </div>
}