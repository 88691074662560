import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { resetCart } from '../../actions/cart';

import Image from '../../assets/images/groceries.png';
import FullWidth from '../../components/basic/FullWidth';

class ThankYou extends Component {
    state = {  }

    componentDidMount(){
        this.props.onResetCart()
    }

    render() { 
        return ( 
            <FullWidth SectionClass="thankyou-wrapper pb-100">
                <div className="white-wrapper">
                <div className="Thk-product">
                    <h2>Thank You</h2>
                    <p>Your Order Placed</p>
                    <img src={Image} alt="fmart" className="img-fluid"/>
                    
                </div>

                <div className="more-details">
                    <p>You can manage your orders in your profile page my orders menu</p>
                    <Link to="/my-account" className="ts-btn">MANAGE ORDERS</Link>
                    <div className="new-shopping">
                        <Link className="yellow-btn" to="/">CONTINUE SHOPPING</Link>
                    </div>
                </div>
                </div>
            </FullWidth>
         );
    }
}


const mapStateToProps = state => ({

});
const mapDispatchToProps = dispatch => ({
    onResetCart:()=>{
        dispatch(resetCart())
    }
});
export default connect(mapStateToProps,
                        mapDispatchToProps)
                        (ThankYou);
 