import React, { Component } from 'react';
import { connect } from 'react-redux';
import FullWidth from '../../components/basic/FullWidth';
import { getCategoryChilds, getChildCategories } from '../../actions/category';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import FilterBar from '../../components/custom/FilterBar';
import { filter, getFilterValues } from '../../actions/search';
import { getCategories } from '../../actions/category';
import ProductList from '../../components/custom/ProductList';
import ABreadcrumb from '../../components/basic/ABreadcrumb';
import CategoryMenu from '../../components/header/CategoryMenu';

const DefaultOffers = [
    {name:'Upto 10%',value:{ from:0,to:10}},
    {name:'10% - 20%',value:{from:10, to:20}},
    {name:"20% - 30%",value:{from:20, to:30}},
    {name:"30% - 40%",value:{from:30, to:40}},
    {name:"40% - 50%",value:{from:40, to:50}},
    {name:"50% - 60%",value:{from:50, to:60}},
    {name:"Above 60%",value:{from:60, to:100}}
]

const DefaultPrices = [
    {name:'Less than AED 20',value:{from:0,to:20}},
    {name:'AED 20 to AED 50',value:{from:20, to:50}},
    {name:'AED 50 to AED 100',value:{from:50, to:100}},
    {name:'AED 100 to AED 200',value:{from:100, to:200}},
    {name:'AED 200 to AED 500',value:{from:200, to:500}},
    {name:'AED 500 to AED 1000',value:{from:500, to:1000}},
    {name:'AED 1000 to AED 5000',value:{from:1000, to:5000}},
    {name:'AED 5000 to AED 10000',value:{from:5000, to:10000}},
    {name:'More than AED 10000',value:{from:10000, to:100000000000000}},
]

const SORT = [
    {name:'Price low to high',by:'low'},
    {name:'Price high to low',by:'high'},
    {name:"Alphabatical order",by:'alpha'}
]


class CategoryProducts extends Component {

    state={
        currentCategory: null,
        defaultPrices: DefaultPrices,
        prices:DefaultPrices,
        brands:[],
        defaultOffers:DefaultOffers,
        offers:DefaultOffers,
        filters: SORT,
        selectedCategories:[],
        selectedPrices:[],
        selectedBrands :[],
        selectedOffers:[],
        selectedFilter: null,
        index:0,
        skip:0,
        take:50,
        changed: false,
        activeTab: null,
        currentId: null
    }

    hasMore = ()=>{
        const { skip } = this.state
        const { count, products } = this.props
        if(products && count>products.length && count>skip){
            return true
        }
        return false
    }

    fetchData = ()=>{
        const { currentCategory, skip, take } = this.state
        const { isLoading, onGetCategoryChilds, store } = this.props
        if(currentCategory && !isLoading && this.hasMore()){
            const newSkip = skip + take
            onGetCategoryChilds(store.id, currentCategory.id, newSkip, take, 0)
            this.setState({skip: newSkip})
        }
    }

    onAppFilter = ()=>{
        const {
            currentCategory,
            selectedBrands,
            selectedOffers,
            selectedPrices,
            selectedFilter
        } = this.state
        var categories = []
        if(currentCategory){
            categories.push(currentCategory.name)
        }

        var data = {
            categories : categories,
            brands: selectedBrands,
            prices:selectedPrices,
            offers:selectedOffers,
            query:'',
            store_id: this.props.store.id,
            offset:this.state.skip,
            limit: this.state.take,
            sort: selectedFilter
        }
        this.props.onGetFilterResult(data, 0)
    }

    onChangeBrands = (selectedBrands)=>{
        this.setState({selectedBrands})
    }

    onChangeOffers = (selectedOffers)=>{
        this.setState({selectedOffers})
    }

    onChangePrices = (selectedPrices)=>{
        this.setState({selectedPrices})
    }

    onChangeSort = (selectedFilter)=>{
        this.setState({selectedFilter})
    }

    componentDidMount(){
        this.setState({currentCategory: null})
        var id = null
        if(this.props.match && this.props.match.params && this.props.match.params.id){
            id = this.props.match.params.id
        }
        if(!id) id = this.props.id
        if(this.props.store && id){
            this.setState({currentId: id})
            this.props.onGetChilds(id, this.props.store.id)
            // this.props.onGetCategories(this.props.store.id,0,50);
        }
    }

    onChangeCategory = (id)=>{
        if(this.props.store){
            this.props.onGetChilds(id, this.props.store.id)
        }
    }

    onChangeTab = (category)=>{
        if(category){
            this.setState({currentCategory: category},()=>{
                this.onCallGetFilterValues()
            })
            const { take } = this.state
            this.props.onGetCategoryChilds(this.props.store.id, category.id, 0, take, 0)
            this.setState({skip:0})
        }
    }

    onCallGetFilterValues = ()=>{
        const id = this.props.match.params.id
        const { 
            currentCategory, 
            selectedPrices, 
            selectedOffers,
            selectedBrands ,
            selectedCategories
        } = this.state
        var data = {}

        var categories = [{category_id: id}]
        if(currentCategory) categories = [{category_id: currentCategory.id}]
        categories = selectedCategories.concat(categories)

        var brands = selectedBrands.filter(function (el) {
            if(typeof el !== 'undefined'){
                return el.id != null || el.id != undefined;
            }
        });

        var prices = selectedPrices.filter(function (el) {
            return el != null;
        });

        var offers = selectedOffers.filter(function (el) {
            return el != null;
        });

        data['categories'] = categories;
        data['store_id'] = this.props.store.id;
        data['offers'] = offers;
        data['prices'] = prices;
        data['brands'] = brands;
        this.props.onGetFilterValues(data)
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.values && nextProps.values.brands){
            var prices = prevState.defaultPrices.filter((el)=>{
                return (nextProps.values.min_price <= el.value.to && el.value.from < nextProps.values.price)
            })

            var offers = prevState.defaultOffers.filter((el)=>{
                return (nextProps.values.min_offer <= el.value.to && el.value.from < nextProps.values.offer)
            })
            return {
                ...prevState,
                brands: nextProps.values.brands,
                offers,
                prices,
                changed: !prevState.changed
            }
        }
        if(nextProps.childCats && !prevState.currentCategory && nextProps.childCats.length > 0){
            nextProps.onGetCategoryChilds(nextProps.store.id, nextProps.childCats[0].id, 0, prevState.take, 0)
            return {...prevState, currentCategory: nextProps.childCats[0], activeTab: nextProps.childCats[0].id}
        }
        if(prevState.currentId && nextProps.id && prevState.currentId != nextProps.id){
            nextProps.onGetChilds(nextProps.id, nextProps.store.id)
            return {...prevState, currentId: nextProps.id, currentCategory: null}
        }
        if(!nextProps.categoryIsLoading && prevState.categoryIsLoading && nextProps.childCats.length < 1 && prevState.currentId){
            nextProps.onGetCategoryChilds(nextProps.store.id, prevState.currentId, 0, prevState.take, 0)
        }

        return {...prevState, categoryIsLoading: nextProps.categoryIsLoading}
    }

    onChangeTab = (tab)=>{
        this.props.onGetCategoryChilds(this.props.store.id, tab, 0, this.state.take, 0)
        this.setState({activeTab: tab})
    }

    render() { 
        const {
            childCats, 
            products, 
            categoryIsLoading, 
            isLoading,
            count,
            categories
        } = this.props

        const {
            brands,
            prices,
            offers,
            changed,
            selectedBrands,
            selectedPrices,
            selectedOffers,
            selectedFilter
        } = this.state

        if(categoryIsLoading){
            return(
                <FullWidth SectionClass="two-rows-wrapper pb-50">
                    <ActivityIndicator />
                </FullWidth>
            )
        }

        return ( 
            <div>
                <div style={{backgroundColor:'white', width:'100%'}}>
                    <FullWidth SectionClass="product-slider">
                        {childCats && childCats.length > 0 && <CategoryMenu 
                            activeTab={this.state.activeTab}
                            disableHome={true} 
                            categories={childCats}
                            sub={true}
                            onChangeTab={this.onChangeTab}/>
                        }
                    </FullWidth>
                </div>
                <FullWidth SectionClass="two-rows-wrapper pb-50">
                
                <div className="flex-grid">
                    {/* <div className="filter-bar f-col" >
                        <div className="flex-title">
                            <div className="left-col">
                                <div className="experience-text">
                                    <h2>Filter</h2>
                                </div>
                            </div>
                            <div className="right-col">
                                <button className="ts-btn">RESET</button>
                            </div>
                        </div>
                        <FilterBar
                            selectedBrands={selectedBrands}
                            selectedPrices={selectedPrices}
                            selectedOffers={selectedOffers}
                            selectedFilter={selectedFilter}
                            onChangeBrands={this.onChangeBrands}
                            onChangePrices={this.onChangePrices}
                            onChangeOffers={this.onChangeOffers}
                            onChangeSort={this.onChangeSort}
                            changed={changed}
                            brands={brands}
                            prices={prices}
                            offers={offers}/>
                        <button className="yellow-btn" onClick={this.onAppFilter}>APPLY</button>
                    </div> */}
                    <div className="product-grid-area f-col" style={{paddingTop:0}}>
                        {/* <ABreadcrumb/> */}
                        {!categoryIsLoading?
                            <ProductList
                                count={20}
                                loading={(isLoading && (!products || products.length<1))}
                                fetchMore={this.fetchData}
                                products={products}
                                history={this.props.history}
                                hasMore={this.hasMore()}/>:null
                        }
                    </div>
                </div>     
                </FullWidth>
            </div>
             
        );
    }
}

const mapStateToProps = state => ({
    products:state.homeReducer.products,
    childCats:state.categoryReducer.childCats,
    categoryIsLoading:state.categoryReducer.cat_loading,
    isLoading: state.homeReducer.isLoading,
    store : state.storeReducer.store,
    index: state.homeReducer.index,
    count:state.homeReducer.count,
    values : state.filterValuesReducer.values,
    isLoadingCat : state.homeReducer.isLoadingCat,
    categories:state.categoryReducer.categories,
});
const mapDispatchToProps = dispatch => ({
    onGetChilds: (category_id, store_id) => {
        dispatch(getChildCategories(category_id, store_id));
    },
    onGetCategoryChilds:(store_id,category_id,skip,take,index)=>{
        dispatch(getCategoryChilds(store_id,category_id,skip,take,index));
    },
    onGetFilterValues:(data)=>{
        dispatch(getFilterValues(data))
    },
    onGetFilterResult:(data,index)=>{
        dispatch(filter(data,index))
    },
    onGetCategories:(store_id,skip,take)=>{
        dispatch(getCategories(store_id,skip,take));
    },
});
const CategoryProductsContainer = connect(
                        mapStateToProps,
                        mapDispatchToProps)
                        (CategoryProducts);
export default CategoryProductsContainer;
