import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { withSnackbar } from 'react-simple-snackbar';
import { Button, Form, Modal } from 'semantic-ui-react'

import { cancelOrder, getCancelReasons, reset } from '../../actions/order';
import AInput from '../basic/AInput';
import ActivityIndicator from '../loaders/ActivityIndicator';

function CancelOrder({
    isOpen,
    onClose,
    reasons,
    loading,
    success,
    onCancelOrder,
    onGetReasons,
    id,
    openSnackbar,
    onDone
}){

    const [initial, setInitial] = useState(true)
    const [active, setActive] = useState(null)
    const [reason, setReason] = useState('')
    const [loader, setLoader] = useState(false)
    const loadRef = useRef()

    useEffect(()=>{
        if(initial){
            onGetReasons()
            setInitial(false)
        }
        if(loadRef && loadRef.current && !loading && loader){
            if(onDone)onDone()
            setLoader(false)
        }
        if(loadRef) loadRef.current = loading
    })

    const onChangeCheckbox = (id)=>{
        setActive(id)
    }

    const onChangeText = (e)=>{
        setReason(e.target.value)
    }

    const onSubmit = ()=>{
        if(!active){
            openSnackbar( "Please choose a reason")
            return
        }
        var data = {
            order_id:id, 
            reason:active, 
            message:reason
        }
        setLoader(true)
        onCancelOrder(data)
    }

    return(
        <Modal
            open={isOpen}
            size="small"
            onClose={onClose}>
            <Modal.Header>Cancel Order</Modal.Header>
            <Modal.Content>
                <ActivityIndicator fullScreen loading={loading}/>
                <Form>
                    <Form.Group grouped>
                        {reasons && reasons.map((item, index)=>(
                            <Form.Checkbox 
                                key={index}
                                checked={item.id===active}
                                onChange={()=>onChangeCheckbox(item.id)}
                                label={item.title} 
                                name='filter' 
                                value={item.id} />
                        ))}
                    </Form.Group>
                </Form>
                <Form>
                    <AInput
                        name="reason"
                        onChange={onChangeText}
                        value={reason}
                        placeholder="Your Message"/>
                </Form>
                <Button  
                    className="fmart-btn" 
                    type='submit' 
                    onClick={onSubmit}>Submit</Button>
            </Modal.Content>
        </Modal>
    )
}


const mapStateToProps = state => ({
    reasons : state.orderReducer.reasons,
    popup: state.orderReducer.popup,
    loading: state.orderReducer.loading,
    success: state.orderReducer.success,
});

const mapDispatchToProps = dispatch => ({
    onGetReasons:()=>{
        dispatch(getCancelReasons())
    },
    onCancelOrder:(data)=>{
        dispatch(cancelOrder(data))
    },
    onReset:()=>{
        dispatch(reset())
    }
});

export default withSnackbar(connect(mapStateToProps,
                        mapDispatchToProps)
                        (CancelOrder));
