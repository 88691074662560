import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'react-simple-snackbar'
import { Button, Form, Modal } from 'semantic-ui-react'
import { addReview, reset } from '../../actions/order'

import AInput from '../basic/AInput'
import Happy from '../../assets/images/emoji-yellow.svg';
import Sad from '../../assets/images/emoji-red.svg';
import Excellent from '../../assets/images/emoji-green.svg';
import ActivityIndicator from '../loaders/ActivityIndicator'

const SMILEYS = [Excellent, Happy, Sad]

function RatingOrder({
    isOpen,
    onClose,
    openSnackbar,
    onAddReview,
    id,
    loading,
    onDone
}){

    const [reason, setReason] = useState('')
    const [selected, setSelected] = useState(null)
    const [loader, setLoader] = useState(false)
    const loadRef = useRef()

    useEffect(()=>{
        if(loadRef && loadRef.current && !loading && loader){
            if(onDone)onDone()
            setLoader(false)
        }
        if(loadRef) loadRef.current = loading
    })

    const onChangeText = (e)=>{
        setReason(e.target.value)
    }

    const onSelectRating = (key)=>{
        setSelected(key)
    }

    const onSubmit = ()=>{
        if(!selected){
            openSnackbar( "Please choose your rating")
            return
        }
        var data = {
            order_id:id, 
            review:reason, 
            rating: selected+1
        }
        setLoader(true)
        onAddReview(data)
    }

    return(
        <Modal
            open={isOpen}
            size="mini"
            onClose={onClose}>
            <Modal.Header>Rate Order</Modal.Header>
            <Modal.Content>
                <ActivityIndicator fullScreen loading={loading}/>
                <Form>
                    <div className="row">
                        {SMILEYS.map((smiley, i)=>(
                            <div className="col-md-4" 
                            onClick={()=>onSelectRating(i)}
                            key={i} 
                            style={{
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center',
                                marginBottom: 30,
                                opacity: i === selected?1:0.5
                            }}>
                                <img src={smiley} style={{width:50, height:50}}></img>
                            </div>
                        ))}
                    </div>
                </Form>
                <Form>
                    <AInput
                        name="reason"
                        onChange={onChangeText}
                        value={reason}
                        placeholder="Your Message"/>
                </Form>
                <Button  
                    className="fmart-btn" 
                    type='submit' 
                    onClick={onSubmit}>Submit</Button>
            </Modal.Content>
        </Modal>
    )
}

const mapStateToProps = state => ({
    popup: state.orderReducer.popup,
    loading: state.orderReducer.loading,
    success: state.orderReducer.success,
});

const mapDispatchToProps = dispatch => ({
    onAddReview:(data)=>{
        dispatch(addReview(data))
    },
    onReset:()=>{
        dispatch(reset())
    }
});

export default withSnackbar(connect(mapStateToProps,
                        mapDispatchToProps)
                        (RatingOrder));

