import React, { Component } from 'react'
import { Button, Modal,Icon, Form } from 'semantic-ui-react'
import SimpleReactValidator from 'simple-react-validator';
import Geocode from "react-geocode";
import { connect } from 'react-redux';

import AInput from '../basic/AInput';
import LocationSearchInput from '../basic/MapField'; 
import { addLocation, getLocations } from '../../actions/user';
import ActivityIndicator from '../loaders/ActivityIndicator';

class AddAdressModal extends Component {

    state = {
        modalOpen: false,
        title:'', 
        villa:'', 
        apartment:'', 
        landmark:'', 
        loc: null,
        load: false
    }

    constructor(props){
        super(props)
        this.validator = new SimpleReactValidator();
    }

    onChangeText = (e)=>{
        this.setState({[e.target.name]: e.target.value})
    }

    handleOpen = () => this.setState({ modalOpen: true })
    handleClose = () => this.setState({ modalOpen: false })

    onSubmit = (e)=>{
        e.preventDefault()
        if( this.validator.allValid() ){
            const {onAddLocation, user} = this.props
            const { villa, apartment, landmark, title, loc } = this.state
            
            if( loc && user){
                
                var data = {
                    villa,
                    apartment, 
                    landmark, 
                    title,
                    customer_id: user.id,
                    latitude: loc.latitude,
                    longitude: loc.longitude
                }
                onAddLocation(data)
                this.setState({load:true})
            }
        }
        else {
          this.validator.showMessages();
          this.forceUpdate();
        } 
    }

    selectLocation = (loc)=>{
        Geocode.fromLatLng(loc.latitude, loc.longitude).then(
            response => {
              const address = response.results[0].formatted_address;
              this.setState({title: address, loc})
            },
            error => {
            }
        );
    }

    componentDidMount(){
        Geocode.setApiKey("AIzaSyAZtjc58tgFOwIO5Fba7tELbNBpL6gh9so");
    }

    componentDidUpdate(prevProps){
        if(!this.props.loading && prevProps.loading){
            if(this.props.success && this.state.load){
                this.props.onGetLocations(this.props.user.id)
                this.handleClose()
                this.setState({load: false})
            }
        }
    }

    render() {
        const {villa, apartment, landmark, title} = this.state
        const { loading } = this.props
        return ( 
            <Modal 
                size="small" 
                trigger={<Button onClick={this.handleOpen}> <Icon name='plus' /> Add new address </Button>}
                open={this.state.modalOpen}
                onClose={this.handleClose}>
                <Modal.Content>
                    <ActivityIndicator fullScreen loading={loading}/>
                    <Form>  
                        <Form.Field>
                            <LocationSearchInput onMapChange={this.selectLocation}/>
                        </Form.Field>
                        <h3>{title}</h3>
                        <AInput
                            onChange={this.onChangeText}
                            name="villa"
                            value={villa}
                            type="text"
                            validator={this.validator}
                            rules='required'
                            placeholder="Villa"/>
                        <AInput
                            onChange={this.onChangeText}
                            name="apartment"
                            value={apartment}
                            type="text"
                            placeholder="Apartment Name"/>
                        <AInput
                            onChange={this.onChangeText}
                            name="landmark"
                            value={landmark}
                            type="text"
                            placeholder="Land Mark"/>
                        <Button  className="fmart-btn" type='submit' onClick={this.onSubmit}>Submit</Button>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={this.handleClose} >
                        <Icon name='remove' /> Cancel
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    loading : state.userReducer.loading,
    success: state.userReducer.success,
    loggedIn : state.userReducer.loggedIn,
    user: state.userReducer.user
});
const mapDispatchToProps = dispatch => ({
    onAddLocation:(data)=>{
        dispatch(addLocation(data))
    },
    onGetLocations:(customer_id)=>{
        dispatch(getLocations(customer_id))
    },
});
export default connect(mapStateToProps,
                        mapDispatchToProps)
                        (AddAdressModal);