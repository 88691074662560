import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import SnackbarProvider from 'react-simple-snackbar'

import App from './App';
import configureStore from './config/ConfigureStore';
import './assets/css/bootstrap.min.css';
import "react-multi-carousel/lib/styles.css";
import 'semantic-ui-css/semantic.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/fmart.css';

const {store,persistor} = configureStore();

var rootEl = document.getElementById('root');
ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider>
            <App />
        </SnackbarProvider>
    </Provider>, rootEl
)

// Are we in development mode?
if (module.hot) {
    // Whenever a new version of App.js is available
    module.hot.accept(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider>
                    <App />
                </SnackbarProvider>
            </PersistGate>
        </Provider>, function () {
        // Require the new version and render it instead
        ReactDOM.render(
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <SnackbarProvider>
                        <App />
                    </SnackbarProvider>
                </PersistGate>
            </Provider>, 
        rootEl)
    })
}


