import React from 'react'
import { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import LOGO from '../../assets/images/Profile.svg';
import FullWidth from '../../components/basic/FullWidth';

class ProfileHeader extends Component{

    render(){
        const { user, loggedIn } = this.props

        if(!loggedIn || !user){
            return(
                <FullWidth SectionClass="edit-profile">
                    <div className="flex-profile">
                        <div className="name-img">
                            <img src={LOGO} alt="fmart" className="img-fluid"/>
                            <div className="dts">
                                <p>Guest</p>
                                <p>+xx xxx xxx xxx</p>
                            </div>
                        </div>
                        <div className="link-right">
                            <Link to="/login" className="btn-border">LOGIN HERE</Link>
                        </div>
                    </div>
                </FullWidth>
            )
        }

        return (
            <FullWidth SectionClass="edit-profile">
                <div className="flex-profile">
                    <div className="name-img">
                        <img src={LOGO} alt="fmart" className="img-fluid profile-pic"/>
                        <div className="dts">
                            <p>{user.first_name}</p>
                            <p>{user.mobile}</p>
                        </div>
                    </div>
                    {/* <div className="link-right">
                        <Link to="/edit-profile" className="btn-border">EDIT</Link>
                    </div> */}
                </div>
            </FullWidth>
        )
    }
}

const mapStateToProps = (state)=>({
    loggedIn : state.userReducer.loggedIn,
    user: state.userReducer.user
})

const mapDispatchToProps = (dispatch) =>({
})

export default connect(mapStateToProps,mapDispatchToProps)(ProfileHeader);