import React, { Component } from 'react';

import { 
    formatDate, 
    tConv24,
    getOrderStatus, 
    getOrderStatusColor, 
    getDeliveryMode 
} from '../../utils';

class OrderCard extends Component {
    
    renderButtons = ()=>{
        const {review, status, changeCancelModal, changeRatingModal} = this.props
        if(status < 5){
            return (
                <div className="btn-wrapper">
                    {/* <button className="fmart-btn bg-green">Reshedule</button> */}
                    <button className="fmart-btn bg-red" onClick={changeCancelModal}>Cancel Order</button>
                </div>
            )
        }

        if(status === 6 && review){
            return(
                <div className="btn-wrapper">
                    <span>You review was {review.rating === 1? "Excellent":review.rating === 2?"Happy":"Sad"}</span>
                </div>
            )
        }

        if(status === 6){
            return(
                <div className="btn-wrapper">
                    <button className="fmart-btn bg-green" onClick={changeRatingModal}>Write Review</button>
                </div>
            )
        }

        return null
    }

    getPaymentType = ()=>{
        const {detail} = this.props
        if(detail && detail.payment_mode){
            if(detail.payment_mode === 1){
                return 'Online'
            }
            if(detail.payment_mode === 2){
                return 'Cash on delivery'
            }
            if(detail.payment_mode === 3){
                return 'Card on delivery'
            }
        }
        return ''
    }

    render() { 
        
        const {
            order_id,
            final_amount,
            status,
            index,
            delivery_mode,
            date,
            from,
            to,
            store
        } = this.props
        return ( 
            <div className="order-box">
                <div className="order-head">
                    <div className="left-head">
                            <div className="small-text">Order {index + 1}</div>
                            <div className="large-text">{order_id}</div>
                    </div>
                    <div className="right-col">
                        <div 
                            className={getOrderStatusColor(status)} 
                            style={{height:30}}>{getOrderStatus(status)}
                        </div>
                    </div>
                </div>

                <div className="order-head">
                    <div className="left-head flext-h">
                        <div>
                            <div className="small-text">{getDeliveryMode(delivery_mode)}</div>
                            <div className="large-text">{formatDate(date)}{from&& ", "+tConv24(from) + " to "+ tConv24(to)}</div>
                        </div>
                        {/* <button className="ts-btn">VIEW DETAILS</button>         */}
                    </div>
                    <div className="right-col">
                        <div className="small-text">{this.getPaymentType()}</div>
                        <div className="big-text"><span className="cr">{store.currency.symbol}</span> {final_amount}</div>
                    </div>
                </div>
                {this.renderButtons()}
                
            </div>
        );
    }
}
 
export default OrderCard;