import React, { Component, useState } from 'react'
import { useSelector } from 'react-redux'
import {Menu } from 'semantic-ui-react'
import ScrollMenu from 'react-horizontal-scrolling-menu';

export default function CategoryMenu({
  activeTab,
  onChangeTab,
  categories,
  disableHome,
  sub=false
}){
  const [activeItem, setActiveItem ] = useState("home")

  const onSelect = (name)=>{
    console.log(name);
    setActiveItem(name)
    onChangeTab(name)
  }

  const MenuItem = ({ text, selected }) => {
    return (
      <div
        className="menu-item"
      >
        {text}
      </div>
    );
  };

   const Menu = (list) => list.map(el => {
    const { name,id } = el;
  
    return (
      <MenuItem
        text={name}
        key={id}
      />
    );
  });

  const Arrow = ({ text, className,childClass }) => {
    return (
      <div 
        className={childClass?'d-none':className}
      >{text}</div>
    );
  };
const ArrowLeft = Arrow({ text: '', className: 'zmdi zmdi-chevron-left',childClass:sub });
const ArrowRight = Arrow({ text: '', className: 'zmdi zmdi-chevron-right',childClass:sub });

var menuItem  = [];
if(!sub){
  menuItem  = [{name:"Home", id:"home"}];
}

categories&&categories.map((item)=> {
  menuItem.push(item)
})
const menu = Menu(menuItem, 0);


  return (
    <div className="menubar">
          <ScrollMenu
          wrapperClass = "child-menu-cat"
          data={menu}
          arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
          onSelect={onSelect}
          selected={activeTab}
          />

    </div>
    )
}