import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetCart } from '../../actions/cart';

import FullWidth from '../../components/basic/FullWidth';
import InvoiceCard from '../../components/custom/InvoiceCard';
import ActivityIndicator from '../../components/loaders/ActivityIndicator';
import AccordionCheckout from './CheckoutAccordion';

class Checkout extends Component {
    state = {  }

    onPay = ()=>{

    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(!this.props.loading && prevProps.loading && this.props.success){
            if(this.props.order ){
                const pro = this.props
                if(this.props.order.payment_mode == 1 && this.props.order.transaction_id){
                    // let header = {
                    //     'Accept': 'application/json',
                    //     'Content-Type': 'application/x-www-form-urlencoded'
                    // };
            
                    // let loginObj = {
                    //     "TransactionID": this.props.order.transaction.TransactionID
                    // };
            
                    // let loginStr = JSON.stringify(loginObj);
            
                    // const sourceObj = {
                    //     uri: this.props.order.transaction.PaymentPortal,
                    //     headers: header,
                    //     body: loginStr,
                    //     method:'POST'
                    // };
                    pro.history.push('/payment-gateway')
                }else{
                    pro.history.push('/order-success')
                    pro.onResetCart()
                }
            }
        }
    }

    render() { 
        const { loading } = this.props
        return ( 
            <FullWidth SectionClass="cart-wrapper pb-100">
                <ActivityIndicator loading={loading} fullScreen/>
                <div className="row">
                    <div className="col-md-8">
                        <div className="checkout-wrapper">
                            <AccordionCheckout/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <InvoiceCard/>
                    </div>
                </div>

            </FullWidth>  
        );
    }
}


const mapStateToProps = state => ({
    loading: state.cartReducer.loading,
    success: state.cartReducer.success,
    order: state.cartReducer.order,
    user: state.userReducer.user,
});

const mapDispatchToProps = dispatch => ({
    onResetCart:()=>{
        dispatch(resetCart())
    }
});
  
export default connect(mapStateToProps, mapDispatchToProps) (Checkout);