import React,{Component} from 'react';
import {isMobile} from 'react-device-detect';
import './Login.css';
import logo from "../../assets/images/logo.png";
import textLogo from "../../assets/images/logotwxt.png";

class FormWrap extends Component {

    enableSide = ()=>{
      const { innerWidth: width, innerHeight: height } = window;
      if(isMobile || width < 480){
        return false
      }
      return true
    }

    render() {
      const enable = this.enableSide()
        return(
          <div className="login-wrapper">
            <div className="container-fluid" >
              <div className="row align-items-center">
                <div className="bg-white col-md-6 order-2">
                  <div className="login-form-wrapper width-cst">
                    <div className="login-box" >
                      <div className="logo">
                        <img src={textLogo} alt="fmart"  />
                      </div>
                      {this.props.children}
                    </div>
                  </div>
                </div>
                { enable ? 
                  <div className="bg-yellow1 col-md-6 order-1">
                  <div className="img-logo width-cst">
                    <div className="logo-rouded">
                    <img src={logo} alt="fmart"  />
                    </div>
                  </div>
                </div>
                :null}
              </div>
            </div>
          </div>
        )
    }
}


export default (FormWrap)




