import React, { Component } from 'react';
import CategoryCard from '../../components/custom/CategoryCard';
import 'swiper/swiper-bundle.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';


class CategorySlider extends Component {

  onClickCategory = (id)=>{
    const {onPress} = this.props
    if(onPress){
      onPress(id)
    }else{
      this.props.history.push('/category/'+id)
    }
  }

  render() { 
    const {categories} = this.props

    if(!categories || categories.length <1) return null
      
    return ( 
      <div className="categories">
          <Swiper slidesPerView={1} spaceBetween={10}  breakpoints={{
          "@0.00": {
          "slidesPerView": 1,
          "spaceBetween": 10
          },
          "@0.75": {
          "slidesPerView": 2,
          "spaceBetween": 20
          },
          "@1.00": {
          "slidesPerView": 3,
          "spaceBetween": 20
          },
          "@1.50": {
          "slidesPerView": 4,
          "spaceBetween": 20
          }
          }} className="mySwiper">
          {categories.map((category, index)=>(
           <SwiperSlide>
                    <CategoryCard 
                onClick={()=>this.onClickCategory(category.id)} 
                key={index} 
                {...category}/>
           </SwiperSlide>
          ))}
          </Swiper> 
      </div>
    );
  }
}
 
export default CategorySlider;