import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react'
import {
    Button,
    Checkbox,
    Form,
    Input,
    Radio,
    Select,
    TextArea,
  } from 'semantic-ui-react'
class PaymentPicker extends Component {

    PayCod = ()=>{
        const { amount, onPlace, store } = this.props
        return (
            <div className="payment-cod">
                <h3>Cash</h3>
                <p>Please keep exact change handy to help us serve you better</p>
                <div className="btn-wrapper">
                  <button className="fmart-btn bg-green" onClick={()=>onPlace(2)}>COD {store ? store.currency.symbol:''} {amount}</button>
                </div>
            </div>
        )
    }

    payCardOnDelivery = ()=>{
      const { amount, onPlace, store } = this.props
      return (
          <div className="payment-cod">
              <h3>Card On Delivery</h3>
              <p>Swipe your card on delivery</p>
              <div className="btn-wrapper">
              <button className="fmart-btn bg-green" onClick={()=>onPlace(3)}>PAY {store ? store.currency.symbol:''} {amount}</button>
              </div>
          </div>
      )
    }

    payOnline = ()=>{
      const { amount, onPlace, store } = this.props
        return (
                <div className="btn-wrapper">
                   <h3>Pay Now</h3>
                <button className="fmart-btn bg-green" onClick={()=>onPlace(1)}>PAY {store ? store.currency.symbol:''} {amount}</button>
                </div>
        )
    }

    cardPay = () =>{
        return (
            <Form>
             <Form.Field
              control={Input}
              label='Card Number'
            />
            <Form.Group widths='equal'>
              <Form.Field
                control={Input}
                label='Exp MM/YY'
              />
              <Form.Field
                control={Input}
                label='CVV'
              />
            </Form.Group>
            <Form.Field
              control={Input}
              label='Name on Card'
            />
            <Form.Field
              control={Checkbox}
              label='Securely save this card for a faster checkout next time'
            />
            <button className="fmart-btn bg-green">PAY AED 530</button>
          </Form>
        )
    }
    
    render() { 
        const panes = [
            { menuItem: 'Cash On Delivery', render: () => <Tab.Pane>{this.PayCod()}</Tab.Pane> },
            { menuItem: 'Online Payment', render: () => <Tab.Pane>{this.payOnline()}</Tab.Pane> },
            // { menuItem: 'Net Baking', render: () => <Tab.Pane>{this.payOnline()}</Tab.Pane> },
          ]
        return ( 
            <div className="pay-wrapper">
              <Tab menu={{ fluid: true, vertical: false, tabular: true }} panes={panes} />
            </div>
         );
    }
}
 
export default PaymentPicker;